import Vue from 'vue'
import Vuex from 'vuex'
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_DATA_GET,
    AUTH_DATA_SET,
} from './actions/auth'
import axios from 'axios'
import api from '@/plugins/helpers/api'
import { request, fetchJson } from '@/plugins/helpers/request.js'
Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
      token: localStorage.getItem('tom-token') || false,
      authStatus: '',
      authError: '',
      data: {},
      system: {},
      toggleSignIn: false,
      userData: {
        ten: 'Tài khoản',
        sodu: 0,
        dongbang: 0,
        sodiachi: [],
        sodienthoai: '',
        email: '',
        cap: 'binh_thuong',
      },
    },
    getters: {
        isAuthenticated: state => (!!state.token || state.authStatus === 'success'),
        authToken: state => state.token,
        authStatus: state => state.authStatus,
        authError: state => state.authError,
        Permisions: state => state.data,
        System: state => state.system,
        SignIn: state => state.toggleSignIn,
        UserData: state => state.userData,
    },
    mutations: {
        [AUTH_REQUEST]: state => {
          state.authStatus = 'loading'
        },
        [AUTH_SUCCESS]: (state) => {
          state.authStatus = 'success'
        },
        [AUTH_ERROR]: (state, Error) => {
          state.authStatus = 'error'
          state.authError = Error
        },
        [AUTH_LOGOUT]: state => {
          state.authStatus = ''
          state.token = false
          state.authError = ''
        },
        [AUTH_DATA_SET]: (state, data) => {
          state.data = data
        },
        System: (state, data) => {
          state.system = data
        },
        ToggleSignIn: (state) => {
          state.toggleSignIn = !state.toggleSignIn
        },
        UpdateUserData: (state, data) => {
          state.userData = data
        },
    },
    actions: {
        ToggleSignIn: ({ commit }) => {
            commit('ToggleSignIn')
        },
        GetSystemData: ({ commit }) => {
            fetch(`${api.URL}/he-thong`).then(fetchJson).then(json => {
                if (json.success === true && json.data) {
                    commit('System', json.data)
                }
            })
        },
        [AUTH_DATA_GET]: ({ commit, dispatch }) => {
            return new Promise((resolve, reject) => {
                request.get(api.AUTH_URL + '/nhanvien/quyen_truy_cap').then(res => {
                const json = res.data
                if (res.status === 200 && json.data) {
                  commit(AUTH_DATA_SET, json.data)
                  resolve(res)
                } else {
                  reject(json)
                }
              }).catch(err => {
                const error = err.response
                if (typeof (error) !== 'undefined') {
                    if (error.status === 401 || error.status === 403) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject({ error: 'AUTH_ERROR' })
                    }
                } else {
                    reject(err)
                }
              })
            })
        },
        [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
            return new Promise((resolve, reject) => {
              commit(AUTH_REQUEST)
              axios.post(api.AUTH_URL + '/dang_nhap', user).then(res => {
                if (res.status === 200 && res.data.token) {
                  const Token = res.data.token
                  localStorage.setItem('tom-token', Token)
                  commit(AUTH_SUCCESS)
                  resolve(res)
                } else {
                  commit(AUTH_ERROR, res.data.error)
                  reject(res.data)
                }
              }).catch(err => {
                commit(AUTH_ERROR, err)
                const error = err.response
                if (typeof (error) !== 'undefined') {
                  if (error.status === 400 || error.status === 403) {
                      reject(error.data.error)
                  }
                } else {
                  commit(AUTH_ERROR, err)
                  localStorage.removeItem('tom-token')
                  reject(err)
                }
              })
            })
        },
        [AUTH_LOGOUT]: ({ commit }) => {
            return new Promise(resolve => {
                localStorage.removeItem('tom-token')
                commit(AUTH_LOGOUT)
                resolve()
            })
        },
    },
    modules: {

    },
})
