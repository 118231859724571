import axios from 'axios'
import { API } from '@/plugins/helpers/api.js'
export const request = axios.create({
  baseURL: API,
  timeout: 60000,
  headers: { Authorization: localStorage.getItem('tom-token') },
})
export function json (resp) {
  return resp.data
}
export function fetchJson (resp) {
  if (resp.status !== 200) {
      return Promise.reject(resp.status)
  }
  return resp.json()
}
