import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import Vuex from 'vuex'
import './plugins'
import '@/assets/scss/_global.scss'
import { store } from './store'
import VueCountdownTimer from 'vuejs-countdown-timer'
import moment from 'moment-timezone'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

// plugin svgrender
// import Vue plugin
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'

// import polyfills for IE if you want to support it
import 'vue-svg-inline-plugin/src/polyfills'

// end:: svgrender

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueApexCharts from 'vue-apexcharts'
import vGoogleTranslate from 'v-google-translate'
Vue.use(vGoogleTranslate)
Vue.use(VueToast, { position: 'top-right' })
Vue.use(Vuex)
Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.use(VueCountdownTimer)
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin)

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: ['src'],
		remove: ['alt'],
	},
})

Vue.component(VueSlickCarousel)
Vue.component('VueSlickCarousel', VueSlickCarousel)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
